//@ts-nocheck
import React,{useEffect} from 'react';
import { Routes, Route, useLocation  } from "react-router-dom";
import './App.css';
import Header from './Layout/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import Footer from './Layout/Footer';
import Home from './page/home/Home';
import Stake from './page/stake/Stake';

import { useTonConnectUI} from '@tonconnect/ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Test from './page/Test/Test';
import Beauties from './page/beauties/Beauties';
import MainHeader from './Layout/MainHeader';
import Scammers from './page/scammers/Scammers';

function App() {
  const [tonConnectUI] = useTonConnectUI();
  const dispatch = useDispatch();
  const location = useLocation();
  const collection = location.pathname.split('/')[1];
  // console.log("array:",collection);
  
  return (
  
  <div className="App" style={{background: location.pathname == '/scammers'? "#000" : "#F5F5F5"}}>
    
    {
    collection? 
      <Header collection={collection}/> 
      : <MainHeader />  
    }
    
    
      <Routes>
        <Route path="/" element={ <Home /> } />
        <Route path="/beauties" element={ <Beauties /> } />
        <Route path="/beauties/stake" element={ <Stake /> } />
        <Route path="/scammers" element={ <Scammers /> } />
        <Route path="/scammers/stake" element={ <Stake /> } />
        <Route path="/test" element={ <Test /> } />
      </Routes>
    <Footer />
    <ToastContainer />
    
  </div>
  );
}

export default App;
