//@ts-nocheck
import React, { useEffect, useState} from 'react'
import { unstake } from '../../component/unstake/unstake'
import { useTonConnectUI , useTonAddress, useTonWallet} from '@tonconnect/ui-react';
import { useStakingContractScammers } from '../../hooks/useStakingContractScammers';
import { useStakingContract } from '../../hooks/useStakingContract';
import { useDispatch, useSelector } from 'react-redux';

/* BASE64 */
import TonWeb from 'tonweb';
import { mnemonicToKeyPair, mnemonicToSeed } from "tonweb-mnemonic";
/* END BASE64 */

const Test = () => {
    const wallet = useTonWallet();
    // const { address, createStake, getNftsList, addNftToContract, getStakedNftsByUser, addRarity, removeRarity} = useStakingContractScammers();
    // const { address, createStake, getNftsList, addNftToContract, getStakedNftsByUser, withdrawTokens } = useStakingContract();
    const {address, createStake, getNftsList, addNftToContract, getStakedNftsByUser, addRarity, removeRarity, withdrawTokens, withdrawTon }  = useStakingContractScammers();
    const contractStore = useSelector((store)=> store.contract);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [rarityType, setRarityType] = useState(0);
    const [reward, setReward] = useState(0);

    /* BASE64 */

    // const tonweb = new TonWeb(new TonWeb.HttpProvider('https://toncenter.com/api/v2/jsonRPC',{
    //     apiKey: "f50d83604dcc776a8e0c84186f32560b48450777bd9b312a419f8591f90f527f"
    // }));
    /* END BASE64 */

    // console.log("staked data: ",contractStore)
    // const getMyContractData = async() => {
    //   await getStakedNftsByUser()
    // }
    // useEffect(()=>{
    //   // console.log("Run...")
    //   getMyContractData();
    // },[wallet])

    // const createStaking = () => {
    //   createStake("EQBFHZO6p-4KMZxE37ogK3pTN3QO4Wms1yDHANqFNWQnTuo_", 7).then((result)=>{
    //     // console.log("Staking Result....", result)
    //   })
    
    // }

    // const handleClaim = () => {
    //   sendClaim("EQAMqfZtbdFcrKuXh1sxX1c16xF_vbaKsHiz7wAFgTPJF4Im", true).then((resData)=>{
    //     console.log("response claim", resData);
    //   })
    // }

    // const getContractNftsHandle = () => {
    //   getNftsList();
    // }

    // const addNftToContractHandle = () => {
    //   // const nft = "EQBFHZO6p-4KMZxE37ogK3pTN3QO4Wms1yDHANqFNWQnTuo_";
    //   // const rarityType = 2;
    //   // console.log(from,to)
    //   const resData = addNftToContract(from,to);
    //   // const rsult = addNftToContract(nft,rarityType);
    //   // console.log("FINAL RESULT:", resData);
    // }

    // const removeContractNftHandle = () => {
    //   removeContractNft("EQBk54ikM1nRYwEP3727BmerSRyrBBHi7E6uhJj_QIQO7IRZ");
    // }

    const withdrawTokensHandle = () => {
      withdrawTokens();
    }

    const withdrawTonHandle = () => {
      withdrawTon();
    }

    // const addRarityHandle= () => {
    //   // console.log("CONFIRM: ", rarityType, reward)
    //   addRarity(rarityType,reward);
    // }

    // const removeRarityHandle= () => {
    //   console.log("CONFIRM: ", rarityType)
    //   removeRarity(rarityType);
    // }

    // const convertToKey = async () => {
    //     const mnemonic = "cable torch recycle income black tribe fog extra twist follow help artefact wild tomato axis require juice endorse purse owner kitchen city kiwi onion"
    //     const mnemonicArr = mnemonic.split(' ');
    //     console.log(mnemonicArr);
    //     if(mnemonicArr.length == 24){
    //         const seed = await mnemonicToSeed(mnemonicArr)
    //         const key = TonWeb.utils.bytesToBase64(seed);
    //         // setBase64Key(key);
    //         console.log("Base64: ", key)
    //     }
    // }


  return (
    <>
        
        {/* <button className='btn btn-transparent-black ms-3' onClick={()=>createStaking()}>7 days stake</button> */}
        {/* <button className='btn btn-transparent-black ms-3' onClick={()=>getContractNftsHandle()}>Get Contract NFTs</button> */}
        
        <div className='ms-3'>
        <div>MAINNET</div>
        <p>contract address: {address}</p>
        <button className='btn btn-transparent-black ms-3' onClick={()=>withdrawTokens()}>Withdraw Tokens</button>
        <button className='btn btn-transparent-black ms-3' onClick={()=>withdrawTonHandle()}>Withdraw TON</button>
        {/* <input type="number" value={from} placeholder='From' onChange={(event)=>setFrom(event.currentTarget.value)} />
        <input type='number' value={to} placeholder='To' onChange={(event)=>setTo(event.currentTarget.value)} /> */}
        {/* <input type="number" value={rarityType} placeholder='Rarity Type' onChange={(event)=>setRarityType(event.currentTarget.value)} />
        <input type='number' value={reward} placeholder='Reward' onChange={(event)=>setReward(event.currentTarget.value)} /> */}
        {/* <button className='btn btn-transparent-black ms-3' onClick={()=>addNftToContractHandle()}>Add Contract NFTs</button> */}
        {/* <button className='btn btn-transparent-black ms-3' onClick={()=>removeRarityHandle()}>Remove Rarity</button> */}
        {/* <button className='btn btn-transparent-black ms-3' onClick={()=>addRarityHandle()}>Add Rarity</button>
        <button className='btn btn-transparent-black ms-3' onClick={()=>convertToKey()}>Convert To BASE64</button> */}
        </div>
        
        {/* <button className='btn btn-transparent-black ms-3' onClick={()=>removeContractNftHandle()}>Remove Contract NFTs</button>
        <button className='btn btn-transparent-black ms-3' onClick={()=>withdrawTokens()}>Withdraw Tokens</button> */}
        {/* <br></br>
        {
          contractStore?.pool?.days7 && contractStore.pool?.days7.length > 0? contractStore.pool?.days7.map( item => 
              <>
              <span>address: {item.address}</span><br/>
              <span>stakedAt: {item.stakedAt}</span><br/>
              <span>unstakeAt: {item.unstakeAt}</span><br/>
              <span>Days: {item.days}</span><br/>
              <button onClick={()=>handleClaim()}>Claim with unstake</button>
              </>
          ) : ''
        } */}
        
    </>
  )
}

export default Test